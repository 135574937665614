const MicroMoveFull = ({ className, forwardRef, ...props }: React.SVGProps<SVGSVGElement> & { forwardRef?: React.MutableRefObject<any> }) => (
    <svg width="3129" height="708" viewBox="0 0 3129 708" fill="none" className={className} {...props} ref={forwardRef}>
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M354 708a354 354 0 1 0 0-708 354 354 0 0 0 0 708Zm-3-355c-11-7-17-22-22-33l-5-13-1-3a1272 1272 0 0 0-24-56c-9-21-19-45-35-60-15-15-35-10-46 4-8 10-15 21-20 33-37 78-36 177-5 256 6 14 13 27 25 38 14 14 34 13 46-2 16-20 23-45 29-70l2-6c3-14 10-41 24-41 11-1 15 12 19 22l2 7c8 18 23 24 36 12 4-5 7-11 10-18 4-9 8-18 15-22 12-6 23 24 26 34l1 2 3 9c6 17 12 34 25 46 15 15 37 12 48-6 12-18 19-38 24-59 8-37 11-75 9-112-2-28-8-55-21-80-13-22-32-28-55-15-5 2-10 6-14 9-19 17-30 39-40 61a376 376 0 0 1-19 36c-7 16-19 39-37 27Z"
            fill="inherit"
        />
        <path
            d="M825 521h61V297h2l90 224h43l90-224h2v224h61V190h-80l-94 238h-1l-94-238h-80v331ZM1243 243c20 0 35-16 35-35s-15-34-35-34c-21 0-36 15-36 34s15 35 36 35Zm-34 278h67V275h-67v246ZM1417 526c66 0 109-41 112-95v-1h-63v2c-4 25-21 41-48 41-33 0-54-27-54-75 0-47 21-76 54-76 27 0 44 18 48 42v2h63v-2c-3-53-45-94-112-94-74 0-121 49-121 128s46 128 121 128ZM1545 521h67V383c0-35 19-55 52-55 10 0 19 1 25 3v-59l-19-2c-29 0-49 18-56 48h-2v-43h-67v246ZM1805 526c74 0 122-48 122-128s-49-128-122-128c-74 0-122 48-122 128s47 128 122 128Zm0-53c-33 0-54-27-54-75s21-76 54-76c32 0 54 28 54 76s-21 75-54 75ZM1951 521h62V297h2l89 224h44l89-224h2v224h62V190h-80l-94 238h-2l-94-238h-80v331ZM2447 526c75 0 122-48 122-128s-48-128-122-128c-73 0-122 48-122 128s47 128 122 128Zm0-53c-33 0-53-27-53-75s21-76 53-76c33 0 54 28 54 76s-21 75-54 75ZM2644 521h75l85-246h-70l-51 187h-1l-52-187h-71l85 246ZM2915 526c70 0 104-41 111-78l1-2h-61l-1 1c-5 14-21 29-49 29-34 0-56-23-56-62h169v-21c0-74-45-123-117-123s-118 50-118 128c0 79 45 128 121 128Zm-2-206c28 0 48 18 52 52h-105c5-34 25-52 53-52ZM3057 316h9v-33h12v-8h-34v8h13v33Zm27 0h8v-26l11 26h6l10-26h1v26h8v-41h-10l-12 28-12-28h-10v41Z"
            fill="inherit"
        />
    </svg>
)

export default MicroMoveFull
