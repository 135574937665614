import ApplicationRouter from "./ApplicationRouter";

function App() {
  return (
    <ApplicationRouter
      routes={[{
        path: '/*',
        id: '03d68d733ea540558ac3a46a0630b87a',
        label: 'About'
      }, {
        path: '/privacy-policy',
        id: '1a1c51628d124a99873fd6525a6f89ff',
        label: 'Privacy policy'
      }, {
        path: '/terms-of-service',
        id: 'dbb3b37c3db74b8f82a2274c6b535f2d',
        label: 'Terms of service'
      }, {
        path: '/cookie-statement',
        id: 'f413b61502414f39926ef0d7edd8c88d',
        label: 'Cookie statement'
      }]}
    />
  );
}

export default App;
