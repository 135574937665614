import { useEffect, useState } from 'react';
import { NotionRenderer } from 'react-notion'
import axios from 'axios';

import 'react-notion/src/styles.css';
import 'prismjs/themes/prism-tomorrow.css';

import Loading from '../Loading';

import type { BlockMapType } from 'react-notion'

type NotionPageProps = {
    id?: string
}

const NotionPage = (props:NotionPageProps) => {
    const [blockMap, setBlockMap] = useState<BlockMapType|null>(null)

    useEffect(() => {
        (async () => {
            if (!props.id) return
            try {
                const response = await axios.get(`https://notion-api.splitbee.io/v1/page/${props.id}`)
                if (response.data) setBlockMap(response.data)
            } catch (e) {}
        })()
    }, [props.id])

    if (!blockMap) return <div className='loading-container'><Loading className='loading'/></div>

    return <NotionRenderer blockMap={blockMap} fullPage hideHeader />
}

export default NotionPage
