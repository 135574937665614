import { useState } from 'react'
import { FiMenu, FiX } from 'react-icons/fi'
import { Route, BrowserRouter, Routes, Link, useLocation } from 'react-router-dom';
import NotionPage from './NotionPage'
import MicroMoveFull from './MicroMoveFull';
import './app.css'

type RouteItem = {
    id: string,
    path: string,
    label: string
}

type RouterProps = {
    routes: RouteItem[]
}

const Nav = ({ r }:{ r: RouteItem }) => {
    const { pathname } = useLocation()
    const rawPath = r.path.replace('*','')
    return (
        <Link to={rawPath} className={`nav-item${pathname === rawPath ? ' nav-item-active':''}`}>
            <div>
                {r.label}
            </div>
        </Link>
    )
}

const ApplicationRouter = (props: RouterProps) => {
    const [drawer, setDrawer] = useState(false)
    return (
        <div className='container'>
            <BrowserRouter>
                <div className={`navigation ${drawer?' navigation-open':''}`}>
                    <a href='https://micromove.io' style={{ cursor: 'default' }}>
                        <MicroMoveFull className='icon' />
                    </a>
                    { props.routes.map(r => <Nav r={r} key={r.id + '_link'}/>)}
                    <p className='company'>© 2022 The Micro Company</p>
                    <button onClick={() => setDrawer(d => !d)} className='hamburger'>
                        {drawer?<FiX/>:<FiMenu/>}
                    </button>
                </div>
                <div className={`content ${drawer?' content-open':''}`}>
                    <Routes>
                        { props.routes.map(r => (
                            <Route
                                key={r.id + '_link'}
                                path={r.path}
                                element={<NotionPage id={r.id} />}
                            />
                        )) }
                    </Routes>
                </div>
            </BrowserRouter>
        </div>
    )
}

export default ApplicationRouter


{/* <Route path='/privacy-policy' element={<NotionPage id='1a1c51628d124a99873fd6525a6f89ff' />}/>
<Route path='/terms-of-service' element={<NotionPage id='dbb3b37c3db74b8f82a2274c6b535f2d' />}/>
<Route path='/cookie-statement' element={<NotionPage id='f413b61502414f39926ef0d7edd8c88d' />}/>
<Route path='/*' element={<NotionPage id='03d68d733ea540558ac3a46a0630b87a' />}/> */}